import { useSearchParams } from "react-router-dom";
import { getTermNameFrom } from "./universities";
import { useEffect } from "react";
export default function CheckoutSuccess() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    window.rdt('track', 'Purchase');
  }, []);

  return (
    <div className="bg-[#FAF7F0] ">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="h-screen flex justify-center items-center mx-auto max-w-4xl ">
          <div className="text-center">
            <p className="font-bold text-3xl">
              We've sent you a confirmation email
            </p>
            <p className="mt-6 text-lg font-medium text-gray-900 text-3xl">
            <span className="font-bold text-[#116A7B]"> You must click the link in the email to opt in to receiving emails from us if you have not already done so before.</span>
            <span className="font-bold text-[#116A7B]"> Make sure</span> to
                check for it including in your spam folder and follow the steps
                outlined in the email to ensure you do not miss a monitoring
                notification.
            </p>
            <p className="mt-6 text-lg font-medium text-gray-900 text-3xl">
            We'll send you another email once you've opted in and we see a spot open up{" "}
              <span className="font-bold text-[#116A7B]">
                {searchParams.get("subject")} {searchParams.get("courseNumber")}{" "}
                {searchParams.get("sections").replaceAll(",", " ")}
              </span>{" "}
              for the{" "}
              <span className="font-bold text-[#116A7B]">
                {getTermNameFrom(
                  searchParams.get("university"),
                  searchParams.get("term")
                )}
              </span>{" "}
              term
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
