import { useSearchParams } from "react-router-dom";
import { useState } from "react";
export default function ConfirmEmail() {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState();
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

  const confirmEmail = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        process.env.REACT_APP_CONFIRM_EMAIL_FUNCTION_URL,
        {
          method: "POST",
          body: JSON.stringify({
            email: searchParams.get("email"),
            token: searchParams.get("t"),
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      let responseData = await response.json();
      console.log(response);
      console.log(responseData);

      if (response.ok && !response.headers.has("X-Amz-Function-Error")) {
        setIsEmailConfirmed(true);
      } else {
        if (responseData.errorCode === "INVALID_TOKEN") {
          setHasError(responseData.errorMessage);
        } else {
          throw new Error("Error Confirming");
        }
      }
    } catch (e) {
      setHasError("Something went wrong, please try again.");
      console.log(e);
    }
    setIsLoading(false);
  };

  return (
    <div className="bg-[#FAF7F0] ">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="h-screen flex justify-center items-center mx-auto max-w-2xl ">
          {isEmailConfirmed ? (
            <div className="text-center">
              <p className="text-[#116A7B] font-bold text-3xl">
                You must click the confirmation link in the email we sent to start monitoring your course selection!
              </p>
              <p className="mt-6 text-lg font-medium text-gray-900 text-3xl">
                We've sent you an email to confirm your registration.
                <span className="font-bold text-[#116A7B]"> Make sure</span> to
                check for it including in your spam folder and follow the steps
                outlined in the email to ensure you do not miss a monitoring
                notification.
              </p>
              <p className="mt-6 text-lg font-medium text-gray-900 text-3xl">
                We'll send you another email once you've confirmed and we see a spot open up for your
                course selection.
              </p>
            </div>
          ) : (
            <div className="text-center">
              <p className="text-[#116A7B] font-bold text-3xl">
                Confirm your email
              </p>
              <p className="mt-6 text-lg font-medium text-gray-900 text-3xl">
                Click confirm to start monitoring your registered course's
                availability for free.
              </p>
              <div className="mt-5">
                <button
                  disabled={isLoading}
                  className="mb-1 text-center block w-full rounded-md bg-[#116A7B] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#116A7B] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#116A7B]"
                  onClick={confirmEmail}
                >
                  {isLoading ? (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      ></path>
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  ) : (
                    <> Confirm</>
                  )}
                </button>
              </div>
              {hasError && <p className="mt-3 text-red-400">{hasError}</p>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
