import { schemas } from "./formSchemas";

export const getTermNameFrom = (university, termId) => {
  return (
    universities[university]?.terms.find((term) => term.id === termId)?.name ??
    ""
  );
};

export const universities = {
  ucalgary: {
    id: "ucalgary",
    name: "University of Calgary",
    formSchema: schemas.ucalgary,
    terms: [
      {
        id: "2251",
        name: "Winter 2025",
      }
    ],
    inputPlaceholders: {
      course: "ECON 201",
      courseComponents: {
        LEC: "01",
        LAB: "B02",
        TUT: "T03",
        SEC: "",
        SEM: "",
        SUP: "",
      },
    },
    emailDomains: ["ucalgary"],
  },
  sfu: {
    id: "sfu",
    name: "Simon Fraser University",
    formSchema: schemas.sfu,
    terms: [
      {
        id: "1251",
        name: "2025 Spring",
      },
    ],
    inputPlaceholders: {
      course: "ECON 103",
      courseComponents: {
        LEC: "D100",
        LAB: "D101",
        TUT: "D102",
        SEM: "",
        OLC: ""
      },
    },
    emailDomains: ["sfu"],
  },
  uottawa: {
    id: "uottawa",
    name: "University of Ottawa",
    formSchema: schemas.uottawa,
    terms: [
      {
        id: "2251",
        name: "2025 Winter Term",
      },
    ],
    inputPlaceholders: {
      course: "CHM 1321",
      courseComponents: {
        LEC: "A00",
        LAB: "A02",
        TUT: "B01",
        DGD: "A01",
        SEM: "W00",
        TLB: "",
        REC: "",
        PRA: "",
        MTR: "",
        STG: "",
        TST: "",
        ADM: ""
      },
    },
    emailDomains: ["uottawa"],
  },
  uoguelph: {
    id: "uoguelph",
    name: "University of Guelph",
    formSchema: schemas.uoguelph,
    terms: [
      {
        id: "STATICtermW25",
        name: "Winter 2025",
      }
    ],
    inputPlaceholders: {
      course: "CHEM 1040",
      courseComponents: {
        Section: "0101"
      },
    },
    emailDomains: ["uoguelph"],
  },
  carleton: {
    id: "carleton",
    name: "Carleton University",
    formSchema: schemas.carleton,
    terms: [
      {
        id: "202510",
        name: "Winter 2025",
      }
    ],
    inputPlaceholders: {
      course: "BIOL 1104",
      courseComponents: {
        "Lecture": "A",
        "Laboratory": "A02",
        "Tutorial": "",
        "Seminar": "",
        "Discussion Group": "",
        "Capstone": "",
        "Comprehensive": "",
        "Directed Studies": "",
        "Field Course": "",
        "Field Placement": "",
        "Film Screening": "",
        "Honours Essay": "",
        "Independent Study": "",
        "Masters Thesis": "",
        "Non-Term Credit": "",
        "Other": "",
        "PhD Thesis": "",
        "Practicum": "",
        "Problem Analysis": "",
        "Research Essay": "",
        "Research Project": "",
        "Studio": "",
        "Undergraduate Thesis": "",
        "Video on Demand": "",
        "Work Term": "",
        "Workshop": ""
    }
  },
    emailDomains: ["cmail.carleton"],
  },
  concordia: {
    id: "concordia",
    name: "Concordia University",
    formSchema: schemas.concordia,
    terms: [
      {
        id: "2244",
        name: "Winter 2025",
      }
    ],
    inputPlaceholders: {
      course: "CHEM 205",
      courseComponents: {
        LEC: "01",
        LAB: "05L",
        TUT: "06T",
        TL: "03TL",
        CON: "A A",
      },
    },
    emailDomains: ["concordia"],
  },
  csub: {
    id: "csub",
    name: "California State University, Bakersfield",
    formSchema: schemas.csub,
    terms: [
      {
        id: "2241",
        name: "Winter Session 2024",
      },
      {
        id: "2243",
        name: "Spring 2024",
      },
    ],
    inputPlaceholders: {
      course: "ECON 2018",
      courseComponents: {
        LEC: "01",
        LAB: "02",
        ACT: "",
        CLN: "",
        CON: "",
        DIS: "03",
        FLD: "",
        IND: "",
        PRA: "",
        RSC: "",
        SEM: "",
        SUP: "",
        THE: "",
        TUT: "",
      },
    },
    emailDomains: ["csub"],
  },
  csuf: {
    id: "csuf",
    name: "California State University, Fullerton",
    formSchema: schemas.csuf,
    terms: [
      {
        id: "2251",
        name: "Winter 2025",
      },
      {
        id: "2253",
        name: "Spring 2025",
      },
    ],
    inputPlaceholders: {
      course: "ECON 201",
      courseComponents: {
        LEC: "03",
        LAB: "01",
        ART: "",
        ACT: "",
        CLN: "",
        CON: "",
        DIS: "04",
        FLD: "",
        IND: "",
        PRA: "",
        RSC: "",
        SEM: "",
        SUP: "",
        THE: "",
        TUT: "",
      },
    },
    emailDomains: ["csu.fullerton"],
  },
  csueb: {
    id: "csueb",
    name: "California State University, East Bay",
    formSchema: schemas.csueb,
    terms: [
      {
        id: "2241",
        name: "Winter InterSession 2024",
      },
      {
        id: "2245",
        name: "Spring Semester 2024",
      },
    ],
    inputPlaceholders: {
      course: "ECON 101",
      courseComponents: {
        LEC: "01",
        LAB: "1A",
        ACT: "",
        CLN: "",
        CON: "",
        DIS: "02",
        FLD: "",
        IND: "",
        PRA: "",
        RSC: "",
        SEM: "",
        SUP: "",
        THE: "",
        TUT: "",
      },
    },
    emailDomains: ["horizon.csueastbay"],
  },
  csumb: {
    id: "csumb",
    name: "California State University, Monterey Bay",
    formSchema: schemas.csumb,
    terms: [
      {
        id: "2241",
        name: "2024 Winter",
      },
      {
        id: "2242",
        name: "2024 Spring",
      },
    ],
    inputPlaceholders: {
      course: "MATH 150",
      courseComponents: {
        LEC: "01",
        ACT: "",
        CLN: "",
        CON: "",
        DIS: "02",
        FLD: "",
        IND: "",
        PRA: "",
        RSC: "",
        SEM: "",
        SUP: "",
        THE: "",
        TUT: "",
      },
    },
    emailDomains: ["csumb"],
  },
  missourist: {
    id: "missourist",
    name: "Missouri S&T",
    formSchema: schemas.missourist,
    terms: [
      {
        id: "5227",
        name: "2024 Spring Semester",
      },
      {
        id: "5235",
        name: "2024 Summer Semester",
      },
    ],
    inputPlaceholders: {
      course: "PHYSICS 1135",
      courseComponents: {
        LEC: "1BB",
        CLN: "",
        CON: "",
        DIS: "",
        EXM: "",
        FLD: "",
        IND: "",
        LES: "",
        ITV: "",
        INT: "",
        LAB: "",
        PRA: "",
        RSD: "201",
        RSC: "",
        SEM: "",
        STU: "",
        SUP: "",
        THE: "",
        TUT: "",
        VID: "",
      },
    },
    emailDomains: ["mst"],
  },
  mizzou: {
    id: "mizzou",
    name: "University of Missouri - Columbia",
    formSchema: schemas.mizzou,
    terms: [
      {
        id: "5235",
        name: "2024 Summer Semester",
      },
      {
        id: "5243",
        name: "2024 Fall Semester",
      }
    ],
    inputPlaceholders: {
      course: "PHYSCS 1200",
      courseComponents: {
        LST: "01",
        LAB: "01A",
        DIS: "",
        EXM: "",
        FLD: "",
        LES: "",
        IST: "",
        IXT: "",
        STU: "",
      },
    },
    emailDomains: ["umsystem"],
  },
  umkc: {
    id: "umkc",
    name: "University of Missouri - Kansas City",
    formSchema: schemas.umkc,
    terms: [
      {
        id: "5227",
        name: "2024 Spring Semester",
      },
      {
        id: "5235",
        name: "2024 Summer Semester",
      },
    ],
    inputPlaceholders: {
      course: "PHYSICS 210",
      courseComponents: {
        LEC: "0001",
        LAB: "001L",
        CLN: "",
        EXM: "",
        EXP: "",
        FLD: "",
        IND: "",
        LES: "",
        IXT: "",
        PRA: "",
        RSD: "",
        STU: "",
        SI: "",
      },
    },
    emailDomains: ["umsystem"],
  },
  umsl: {
    id: "umsl",
    name: "University of Missouri - St. Louis",
    formSchema: schemas.umsl,
    terms: [
      {
        id: "5227",
        name: "2024 Spring Semester",
      },
    ],
    inputPlaceholders: {
      course: "ECON 1001",
      courseComponents: {
        LEC: "001",
        FLD: "",
        IND: "",
        LES: "",
        ITV: "",
        INT: "003",
        LAB: "",
        RSD: "",
        STU: "",
        VID: "",
      },
    },
    emailDomains: ["umsl", "umsystem"],
  },
  uhouston: {
    id: "uhouston",
    name: "University of Houston",
    formSchema: schemas.uhouston,
    terms: [
      {
        id: "2220",
        name: "Spring 2024",
      },
    ],
    inputPlaceholders: {
      course: "ECON 2301",
      courseComponents: {
        LEC: "1",
        LAB: "02",
        ALT: "",
        CLN: "",
        CBE: "",
        CON: "",
        DIS: "",
        DST: "",
        FLD: "",
        IND: "",
        INV: "",
        LLB: "",
        PRA: "",
        PCO: "",
        PRC: "",
        PLS: "",
        RSC: "",
        SEM: "",
        SUP: "",
        THE: "",
        TUT: "",
      },
    },
    emailDomains: ["uh"],
  },
};
