export default function ConfirmationSuccess() {

  return (
    <div className="bg-[#FAF7F0] ">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="h-screen flex justify-center items-center mx-auto max-w-4xl ">
          <div className="text-center">
            <p className="text-[#116A7B] font-bold text-3xl">
              You're all set! We will now start monitoring the availability of your selected course(s)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
