import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Home";
import CheckoutSuccess from "./CheckoutSuccess";
import Unsubscribe from "./Unsubscribe";
import TermsOfService from "./TermsOfService";
import Pricing from "./Pricing";
import ReactGA from "react-ga4";
import UniversityValidator from "./UniversityValidator";
import Refer from "./Refer";
import ConfirmReferral from "./ConfirmReferral";
import ConfirmEmail from "./ConfirmEmail";
import PrivacyPolicy from "./PrivacyPolicy";
import ContactUs from "./ContactUs";
import ConfirmationSuccess from "./ConfirmationSuccess"

ReactGA.initialize("G-56QXED1655");

const router = createBrowserRouter([
  {
    path: "/r/:university/pricing",
    element: <Pricing />,
  },
  {
    path: "/r/:university",
    element: (
      <UniversityValidator>
        <Home />
      </UniversityValidator>
    ),
    children: [
      {
        path: "pricing",
        element: <Pricing />,
      },
    ],
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/checkout/success",
    element: <CheckoutSuccess />,
  },
  {
    path: "/unsubscribe",
    element: <Unsubscribe />,
  },
  {
    path: "/termsofservice",
    element: <TermsOfService />,
  },
  {
    path: "/privacypolicy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/contactus",
    element: <ContactUs />,
  },
  {
    path: "/refer",
    element: <Refer />,
  },
  {
    path: "/referral",
    element: <ConfirmReferral />,
  },
  {
    path: "/email-confirmation",
    element: <ConfirmEmail />,
  },
  {
    path: "/opted-in",
    element: <ConfirmationSuccess/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
